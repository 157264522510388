import React from "react";
import { Select, Icon, Loading } from "web3uikit";
import {
  AdvancedRealTimeChart,
  TickerTape,
  SingleTicker,
  Ticker,
  MiniChart,
} from "react-ts-tradingview-widgets";
import { RedditDailyUser } from "./reddit-daily-cc";

var Interval: any = "5";
var TickerSymbol = localStorage.getItem("storageTicker");
var tickerNum = localStorage.getItem("tickerNum");

if (tickerNum == null) {
  tickerNum = "0";
}

if (TickerSymbol == null) {
  TickerSymbol = "COINBASE:BTCUSD";
}

class Body extends React.Component {
  refresh = (input: any) => {
    // re-renders the component
    this.setState({ input });
  };

  tickerChange = (input: any) => {
    if (input == "BNB") {
      TickerSymbol = "BINANCE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "8";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "BTC") {
      TickerSymbol = "COINBASE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "0";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "ETH") {
      TickerSymbol = "COINBASE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "1";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "AVAX") {
      TickerSymbol = "COINBASE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "6";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "DOGE") {
      TickerSymbol = "COINBASE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "7";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "SHIB") {
      TickerSymbol = "COINBASE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "8";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "PENG") {
      TickerSymbol = "MEXC:" + input + "USDT";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "3";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "MATIC") {
      TickerSymbol = "COINBASE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "7";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    } else if (input == "ANKR") {
      TickerSymbol = "COINBASE:" + input + "USD";
      localStorage.setItem("storageTicker", TickerSymbol);
      tickerNum = "2";
      localStorage.setItem("tickerNum", tickerNum!.toString());
    }
    console.log(TickerSymbol);
    this.refresh(SingleTicker);
    return TickerSymbol;
  };

  timeChange = (input: any) => {
    Interval = "" + input;
    console.log(Interval);
    this.refresh(SingleTicker);
    return Interval;
  };

  render() {
    return (
      <div className="body">
        <div className="dropdown-time">
          <Select
            defaultOptionIndex={2}
            //defaultOptionIndex={Number(localStorage.getItem("tickerTime"))}
            onChange={(drop: any) => this.timeChange(drop.id)}
            width={"130px"}
            options={[
              {
                id: "1",
                label: "1min",
                prefix: "🕒",
              },
              {
                id: "3",
                label: "3min",
                prefix: "🕒",
              },
              {
                id: "5",
                label: "5min",
                prefix: "🕒",
              },
              {
                id: "15",
                label: "15min",
                prefix: "🕒",
              },
              {
                id: "30",
                label: "30min",
                prefix: "🕒",
              },
              {
                id: "60",
                label: "60min",
                prefix: "🕒",
              },
              {
                id: "120",
                label: "120min",
                prefix: "🕒",
              },
              {
                id: "180",
                label: "180min",
                prefix: "🕒",
              },
              {
                id: "240",
                label: "240min",
                prefix: "🕒",
              },
              {
                id: "D",
                label: "Day",
                prefix: "🗓",
              },
              {
                id: "W",
                label: "Week",
                prefix: "🗓",
              },
            ]}
          />
        </div>

        <div className="dropdown-crypto">
          <Select
            defaultOptionIndex={Number(localStorage.getItem("tickerNum"))}
            onChange={(drop: any) => this.tickerChange(drop.id)}
            width={"130px"}
            options={[
              {
                id: "BTC",
                label: "BTC",
                prefix: <Icon fill="#68738D" svg="btc" />,
              },
              {
                id: "ETH",
                label: "ETH",
                prefix: <Icon fill="#68738D" svg="eth" />,
              },
              {
                id: "ANKR",
                label: "ANKR",
                prefix: "⚓️",
              },
              {
                id: "PENG",
                label: "PENG",
                prefix: "🐧",
              },
              {
                id: "BNB",
                label: "BNB",
                prefix: <Icon fill="#68738D" svg="bnb" />,
              },
              {
                id: "AVAX",
                label: "AVAX",
                prefix: <Icon fill="#68738D" svg="avax" />,
              },
              {
                id: "DOGE",
                label: "DOGE",
                prefix: <Icon fill="#68738D" svg="doge" />,
              },
              {
                id: "SHIB",
                label: "SHIB",
                prefix: <Icon fill="#68738D" svg="shib" />,
              },
              {
                id: "MATIC",
                label: "MATIC",
                prefix: <Icon fill="#68738D" svg="matic" />,
              },
            ]}
          />
        </div>

        <div className="single-ticker">
          <SingleTicker
            colorTheme="dark"
            width="100%"
            symbol={TickerSymbol!}
            isTransparent={true}
            largeChartUrl="https://www.tobym.dev"
          ></SingleTicker>
        </div>

        <div className="main-chart">
          <AdvancedRealTimeChart
            theme="dark"
            autosize={true}
            symbol={TickerSymbol!}
            hide_top_toolbar={true}
            hide_side_toolbar={true}
            withdateranges={false}
            hide_legend={true}
            interval={Interval}
          ></AdvancedRealTimeChart>
        </div>

        <div className="ticker-tape">
          <TickerTape
            colorTheme="dark"
            displayMode="regular"
            isTransparent={false}
            symbols={[
              {
                proName: "COINBASE:BTCUSD",
                title: "BTC/USD",
              },
              {
                proName: "COINBASE:ETHUSD",
                title: "ETH/USD",
              },
              {
                proName: "BINANCE:BNBUSD",
                title: "BNB/USD",
              },
              {
                proName: "BINANCE:SOLUSD",
                title: "SOL/USD",
              },
              {
                proName: "BINANCE:DOGEUSD",
                title: "DOGE/USD",
              },
              {
                proName: "COINBASE:SHIBUSD",
                title: "SHIB/USD",
              },
              {
                proName: "BINANCE:MATICUSD",
                title: "MATIC/USD",
              },
              {
                proName: "BINANCE:ANKRUSD",
                title: "ANKR/USD",
              },
            ]}
          ></TickerTape>
        </div>

        <div className="chat-base card-chat-base card-dark">
          <div className="loading">
            <Loading size={12} spinnerColor="#2E7DAF" spinnerType="wave" />
          </div>

          <RedditDailyUser />

          {/*
          <div>
            <MiniChart
              colorTheme="dark"
              autosize
              symbol="COINBASE:BTCUSD"
              dateRange="1D"
              trendLineColor="rgba(199, 255, 254, 1)"
              underLineColor="rgba(71, 255, 252, .2)"
            ></MiniChart>
          </div>
          <div className="minichart">
            <MiniChart
              colorTheme="dark"
              autosize
              symbol="COINBASE:ETHUSD"
              dateRange="1D"
              trendLineColor="rgba(199, 255, 254, 1)"
              underLineColor="rgba(71, 255, 252, .2)"
            ></MiniChart>
          </div>
          <div className="minichart">
            <MiniChart
              colorTheme="dark"
              autosize
              symbol="SPY"
              dateRange="1D"
              trendLineColor="rgba(199, 255, 254, 1)"
              underLineColor="rgba(71, 255, 252, .2)"
            ></MiniChart>
          </div>
                  */}
        </div>
      </div>
    );
  }
}
export default Body;
