import logo from "./logo.svg";
import React from "react";
import "./App.css";
import Header from "./header";
import "./css/styles.css";
import Body from "./body";
import { MoralisProvider } from "react-moralis";

function App() {
    return (
        <MoralisProvider
            appId="O7nD0t09Vt5FPr5dudMT2svTbDGD72EDopuTfbEu"
            serverUrl="https://pxgnmgjtug7j.usemoralis.com:2053/server"
        >
            <div className="App">
                <Header />

                <div className="body">
                    <Body />
                </div>
            </div>
        </MoralisProvider>
    );
}

export default App;
