import React from "react";
import snoowrap from "snoowrap";
import Speech from "speak-tts";

const r = new snoowrap({
    userAgent: "webapp:meowcharts.com:v1 (by u/SerfToby)",
    clientId: "CNS_AFJTs9eFVneVJU7_jw",
    clientSecret: "EsV_QylDC-Mn8lbCuzeamB8SbtY8pA",
    refreshToken: "20390502-ZlarYsfMynuNHgXhFTYDgtw0iFDAvQ",
});

var sub = "";
var commentNum = 1;
var DailyUser = [];
var DailyComment = [];
var DailyTime = [];

const speech = new Speech();

speech
    .init({
        volume: 1,
        lang: "en-US",
        rate: 1,
        pitch: 1,
        voice: "Google US English",
        splitSentences: true,
        listeners: {
            onvoiceschanged: (voices) => {
                console.log("Event voiceschanged", voices);
            },
        },
    })
    .then((data) => {
        // The "data" object contains the list of available voices and the voice synthesis params
        console.log("Speech is ready, voices are available", data);
    })
    .catch((e) => {
        console.error("An error occurred while initializing : ", e);
    });

speech.pause();

var sleepTime = 10000;
const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

class RedditDailyUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Comment: props.name,
        };
    }

    timeSensitiveAction = async () => {
        try {
            //do something here
            while (true) {
                await sleep(sleepTime); //wait sleepTime seconds
                //continue on...
                await console.log("Daily ID:");
                await this.functionGetSub();
                await console.log("User:");
                await this.functionUser();
                await console.log("Comment:");
                await this.functionComment();
                await console.log("Time:");
                await this.functionTimeStamp();
            }
        } catch (err) {
            console.log(err);
        }
    };

    refresh = (input) => {
        // re-renders the component
        this.setState({ input });
    };

    componentDidMount() {
        this.functionGetSub();
        this.functionUser();
        this.functionComment();
        this.functionTimeStamp();
        this.timeSensitiveAction();
    }

    functionGetSub = async () => {
        try {
            var dailySub = await r.getSubreddit("CryptoCurrency").search({
                query: '"Daily Crypto Discussion -"',
                time: "week",
                sort: "new",
            })[0].id;
            console.log(dailySub);
            sub = dailySub;

            this.setState({
                Daily: dailySub,
            });
        } catch (err) {
            console.log(err);
        }
    };

    functionUser = async () => {
        try {
            var dailyU = (
                await r.getSubmission(sub).expandReplies({ limit: 1, depth: 0 })
            ).comments[commentNum].author.name;
            console.log(dailyU);
            var deleted = false;

            while (DailyUser.length > DailyComment.length) {
                DailyUser.pop();
                console.log("Deleted one User");
                deleted = true;
            }

            if (deleted === true) {
                return console.log("comment stop");
            }

            var countDailyUser = DailyUser.length;

            if (DailyUser[countDailyUser - 1] !== dailyU) {
                DailyUser.push(dailyU);
                console.log(DailyUser);
            }

            this.setState({
                Daily: dailyU,
            });
        } catch (err) {
            console.log(err);
        }
    };

    functionComment = async () => {
        try {
            var dailyC = (
                await r.getSubmission(sub).expandReplies({ limit: 1, depth: 0 })
            ).comments[commentNum].body;
            var deleted = false;

            var dailyU = (
                await r.getSubmission(sub).expandReplies({ limit: 1, depth: 0 })
            ).comments[commentNum].author.name;

            console.log(dailyC);

            while (DailyComment.length > DailyUser.length) {
                DailyComment.pop();
                console.log(DailyComment.length);
                console.log("Deleted one Comment");
                deleted = true;
            }

            if (deleted === true) {
                return console.log("comment stop");
            }

            var countDailyComment = DailyComment.length;

            if (DailyComment[countDailyComment - 1] !== dailyC) {
                DailyComment.push(dailyC);
                console.log(DailyComment);

                if (DailyComment.length > DailyUser.length) {
                    return console.log("Speech Failed");
                } else {
                    speech
                        .speak({
                            text: dailyU + " Said ..." + dailyC,
                        })
                        .then(() => {
                            console.log("Success !");
                        })
                        .catch((e) => {
                            console.error("An error occurred :", e);
                        });
                }
            }

            this.setState({
                Daily: dailyC,
            });
        } catch (err) {
            console.log(err);
        }
    };

    functionTimeStamp = async () => {
        try {
            var dailyT = (
                await r.getSubmission(sub).expandReplies({ limit: 1, depth: 0 })
            ).comments[commentNum].created;

            var date = new Date(dailyT * 1000);
            var newDate = date.toLocaleTimeString("en-US");
            var deleted = false;
            console.log(newDate);

            var countDailyComment = DailyTime.length;

            while (DailyTime.length > DailyUser.length) {
                DailyTime.pop();
                console.log(DailyTime.length);
                console.log("Deleted one Time");
                deleted = true;
            }

            if (deleted === true) {
                return console.log("time stop");
            }

            if (DailyTime[countDailyComment - 1] !== newDate) {
                DailyTime.push(newDate);
                console.log(DailyTime);
            }

            this.setState({
                Daily: newDate,
            });
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        let zipped = DailyUser.map((x, i) => [
            <p className="account-chat">{x}</p>,
            <p className="redditComment">{DailyComment[i]}</p>,
            <p className="chatTime">{DailyTime[i]}</p>,
        ]);

        var cardRender = zipped.map((cardNum) => (
            <div className="card-message">
                <p> {cardNum} </p>
            </div>
        ));

        return <div className="cardReverse">{cardRender}</div>;
    }
}

export { RedditDailyUser };
