import React from "react";
import Logo from "./images/Meow-Charts-Logo.svg";
import Twitter from "./images/twitter2.svg";
import Mute from "./images/mute.svg";
import UnMute from "./images/unmute.svg";
import { Typography } from "web3uikit";
import Speech from "speak-tts";

const speech = new Speech();

const isEven = require("is-even");

let count = 1;

let volImg: any = Mute;

class Header extends React.Component {
    constructor(props: any) {
        super(props);
        this.chatMute = this.chatMute.bind(this);
    }

    chatMute = () => {
        speech.pause();
        console.log("Muted");
    };

    chatUnmute = () => {
        speech.cancel();
        speech.resume();
        console.log("UnMute");
    };

    volumeButton = () => {
        if (isEven(count) === true) {
            volImg = Mute;
            this.forceUpdate(this.render);
            this.chatMute();
        } else {
            volImg = UnMute;
            this.forceUpdate(this.render);
            this.chatUnmute();
        }
        count = count + 1;
    };

    render() {
        return (
            <div className="header_dapp">
                <div className="header_top">
                    <div className="logo">
                        <embed src={Logo} />
                        <span className="title">Meow Charts</span>
                        <button
                            className="btn btn--link text text--small text--medium btn-mute"
                            onClick={() => this.volumeButton()}
                        >
                            <img
                                className="volBut"
                                alt="mute"
                                src={volImg}
                            ></img>
                        </button>
                    </div>
                    <nav>
                        {/*
            <div className="connect-btn">
            <ConnectButton />
            </div>
            */}
                        <a
                            href="https://www.eloncatmoon.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="a-elon"
                        >
                            <div className="badge-container-add">
                                <span className="badge-add badge--dark-add text--small">
                                    ElonCatMoon (ECAT)
                                </span>
                                <span className="text text--small text--regular text--white badge-add2">
                                    <Typography>
                                        Check out the New Meme Coin on The Block
                                        🚀
                                    </Typography>
                                </span>
                            </div>
                        </a>
                        <div className="btn-mute">
                            <a></a>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}
export default Header;
